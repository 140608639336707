import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import QuestionsFormModalManager from "./widgets/questions_form_modal_widget/managers/QuestionsFormModalWidgetManager";

export const ReviewsApplicationConfigToken = Symbol(
    "ReviewsApplicationConfigToken"
);

export const reviewsApplicationLoggerToken = Symbol(
    "ReviewsApplicationLoggerToken"
);

export const reviewsAuthApiClientToken = Symbol(
    "reviewsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const reviewsTranslationsAuthApiClientToken = Symbol(
    "reviewsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const LeaveReviewPageApplicationConfigToken = Symbol(
    "LeaveReviewPageApplicationConfigToken"
);

export const QuestionsFormModalManagerToken = Symbol(
    "QuestionsFormModalManagerToken"
) as InjectionToken<QuestionsFormModalManager>;
